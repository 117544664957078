<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <!-- <span>
              商务负责人：
              <el-select
                class="ipt_width"
                clearable
                v-model="parameter.principalUserId"
                filterable
                placeholder="请输入或者选择"
              >
                <el-option
                  v-for="item in selectedList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span> -->

            <el-button
              type="primary"
              icon="el-icon-search"
              @click="pageChangeHandler(1)"
              :loading="loading"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- <el-tabs v-model="parameter.performanceStatus" class="fullScreenMainHeader">
            <el-tab-pane label="待核算" name="3"></el-tab-pane>
          </el-tabs> -->

          <el-table
            row-key="id"
            :data="tableData"
            border
            style="width: 100%"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              label="商务名称"
              :show-overflow-tooltip="false"
              min-width="240"
            >
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="ownerUnit"
              label="业主单位"
              :show-overflow-tooltip="false"
              min-width="240"
            >
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="principalUserName"
              label="商务负责人"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="sumPerformanceValue"
              label="当前绩效金额"
              min-width="120"
            >
              <template slot-scope="scope">
                <span style="color: red">{{ scope.row.sumPerformanceValueFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sumCostTally" label="当前记账金额" min-width="120"
              ><template slot-scope="scope">
                <span style="color: green">{{ scope.row.sumCostTallyFormat }}</span>
              </template></el-table-column
            >
            <!-- <el-table-column align="center" prop="businessType" label="业务类型" width="110">
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  class="text_Miantan_Bgc"
                  >绩效
                </el-button>
                <el-button
                  @click="showCostDialog(scope.row)"
                  :loading="isCostDialogLoading"
                  type="text"
                  size="small"
                  class="text_RESET_PASSWORD_Bgc"
                  >费用
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <IndividualBusinessComputation
      v-if="showEditDialog"
      @updateData="getData"
      :showEditDialog.sync="showEditDialog"
      :ID="ID"
      :distriBution="distriBution"
    ></IndividualBusinessComputation>

    <EditDialog width="70%" :isShow.sync="costDialog_show">
      <div slot="title" class="title">
        <div>个人记账明细</div>
      </div>
      <div id="costDialog" slot="content" class="content" style="min-height: 400px">
        <el-card v-loading="costListLoading">
          <div class="cost-dialog-title">
            <el-descriptions border size="medium">
              <el-descriptions-item label="商务名称" labelStyle="min-width: 90px;">{{
                costDialog_data.businessName
              }}</el-descriptions-item>
              <el-descriptions-item
                label="商务负责人"
                labelStyle="min-width: 100px;"
                contentStyle="min-width: 90px;"
                >{{ costDialog_data.principalUserName }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
          <el-table :data="costDialog_data.costList" border height="350" style="width: 100%">
            <el-table-column type="index" align="center" label="序号" width="70"> </el-table-column>
            <el-table-column prop="date" align="center" label="记账时间"
              ><template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="moduleType" min-width="120" align="center" label="记账类型">
              <template slot-scope="scope">
                {{ scope.row.moduleType | dict(dictData.moduleType) }}
              </template>
            </el-table-column>
            <el-table-column prop="sumCost" align="center" label="报销金额" min-width="100">
            </el-table-column>
            <el-table-column prop="costTally" align="center" label="记账金额" min-width="100">
            </el-table-column>
            <el-table-column
              prop="staffBusinessPerformance"
              width="150"
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="showCostDetail(scope.row)"
                  :loading="costDetailLoading"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleCostSizeChange"
              @current-change="handleCostCurrentChange"
              :current-page="costListParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="costListParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="costDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <BusinessPerformanceDetailDialog
      v-if="costDetailDialog_show"
      :costDetailDialog_show="costDetailDialog_show"
      :costDetail="costDialog_data.costDetail"
      :type="costDialog_data.moduleType"
      :imgList="imgList"
      @closeDialog="handleCloseCostDetail"
    ></BusinessPerformanceDetailDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    IndividualBusinessComputation: () =>
      import('@/views/publicView/IndividualBusinessComputation.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    BusinessPerformanceDetailDialog: () =>
      import('@/components/BusinessPerformanceDetailDialog.vue'),
  },
  data() {
    return {
      businessList: [],
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null, //商务名称
        principalUserId: null,
        performanceStatus: '3',
      },
      loading: false,
      pageSize: 0,
      tableData: [],
      showEditDialog: false,
      dictData: {
        businessType: [],
        companyType: [],
        moduleType: [],
      },
      ID: '',
      distriBution: false,
      selectedList: [],

      costListParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
        targetId: '',
      }, //dialogVisible
      costDialog_data: {
        moduleType: '',
        businessName: '',
        businessManageId: '',
        principalUserName: '',
        principalUserId: '',
        selectName: '', //点击左侧明细后右侧显示姓名
        selectRole: '', //点击左侧明细后右侧显示角色
        selectId: '', //点击左侧明细后右侧显示id
        costList: [], //右侧员工明细
        costDetail: {},
      },
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false,

      isCostDialogLoading: false, //报销详情button按钮的loading
      costListLoading: false, //弹框右侧表格loading
      costDetailLoading: false, //弹框内详情按钮loading

      img_index: 0, //附件图片预览下标
      imgList: [], //附件图片数组
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      highlightedCell: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),
    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDialog_data.costDetail.detailList) {
        sum = this.costDialog_data.costDetail.detailList.reduce(
          (total, item) => (total += Number(item.cost)),
          0
        )
      }
      return sum
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_MODULE_TYPE', true)
      .then(res => {
        this.dictData.moduleType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    'parameter.performanceStatus': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate() {
      this.$forceUpdate()
    },
    handleClick(row) {
      this.distriBution = true
      this.ID = row.id
      this.showEditDialog = true
    },
    async getData() {
      this.loading = true
      const res = await this.$api.businessPerformance.allocationAllListToStaff(this.parameter)
      if (res.data == null) {
        let arr = []
        this.tableData = arr
      } else {
        this.tableData = res.data.records
        this.parameter.total = res.data.total
        // 格式化金额
        if (this.tableData) {
          this.tableData.forEach(item => {
            item.sumPerformanceValueFormat = numberToCurrencyNo(item.sumPerformanceValue)
            item.sumCostTallyFormat = numberToCurrencyNo(item.sumCostTally)
          })
        }
      }
      this.loading = false
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.parameter.pageNow = 1
      this.parameter.businessName = null
      this.parameter.principalUserId = null
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 查看商务支出信息
    showCostDialog(scope) {
      this.costDialog_data = {
        businessName: '',
        businessManageId: '',
        principalUserName: '',
        costList: [],
        costDetail: {},
      }
      this.isCostDialogLoading = true
      this.costDialog_show = true
      this.costDialog_data.businessName = scope.businessName
      this.costDialog_data.businessManageId = scope.id
      this.costDialog_data.principalUserName = scope.principalUserName
      this.getCostList()
      this.isCostDialogLoading = false
    },

    // 获取左侧商务报销列表数据
    async getCostList() {
      this.costListLoading = true
      this.costDialog_data.costList = []
      this.costListParams.businessManageId = this.costDialog_data.businessManageId
      this.costListParams.targetId = this.userInfo.staffId
      const { data: res } = await this.$api.businessTally.listToStaff(this.costListParams)
      this.costListLoading = false
      if (res?.records) {
        this.costListParams.total = res.total
        this.costDialog_data.costList = res.records
      }
    },
    handleCostSizeChange(val) {
      this.costListParams.pageSize = val
      this.costListParams.pageNow = 1
      this.getCostList()
    },
    handleCostCurrentChange(val) {
      this.costListParams.pageNow = val
      this.getCostList()
    },
    // 打开报销详情信息对话框
    async showCostDetail(val) {
      this.costDetailLoading = true
      this.costDialog_data.costDetail = {}
      this.costDialog_data.moduleType = val.moduleType
      if (val.moduleType == 'BUSINESS_EXPENSE') {
        const { data: res } = await this.$api.businessExpense.selectDetailById(val.professionalId)
        this.costDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      } else if (val.moduleType == 'BUSINESS_REGISTRATION_EXPENDITURE') {
        const { data: res } = await this.$api.register.selectDetailById(val.professionalId)
        this.costDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      }
      this.costDialog_data.costDetail.detailList.forEach(item => {
        item.expenseFileList.forEach(v => {
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        })
      })
      this.costDetailDialog_show = true
    },

    handleCloseCostDetail() {
      this.costDetailDialog_show = false
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
#costDialog {
  /deep/ .el-table th {
    background: #ddeeff;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }
  .cost-dialog-title {
    margin-bottom: 10px;
  }
}

.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

.empty-detail {
  height: 444px;
  color: grey;
  font-size: 14px;
  text-align: center;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: rgb(241, 212, 158);
}

/deep/.select-item {
  background: rgb(241, 212, 158);
}
</style>
